import { I18N } from 'aurelia-i18n'
import { handleConnectionError } from './connectionError'
import { ErrorPresentation } from './ErrorHandler'
import { handleErrorResponse } from './ErrorResponse'

const defaultErrorHandlers = [handleErrorResponse, handleConnectionError]

export function getErrorPresentation(e: unknown, i18n: I18N): ErrorPresentation {
  let message: ErrorPresentation | undefined = undefined
  for (const handler of defaultErrorHandlers) {
    message = handler(e, i18n)
    if (message) break
  }

  if (message) return message
  

  if (e instanceof Error) return { errorMessage: e.message }
  return { errorMessage: i18n.tr('error:unknown-error') }
}
