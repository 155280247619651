import { inject } from 'aurelia-dependency-injection'
import { LocaleService } from 'services/locale-service'

@inject(LocaleService)
export class VersionFormatValueConverter {
  
  constructor(private readonly locale:LocaleService){

  }

  public toView(value: number | null | undefined): string {
    if (value === null || value === undefined) {
      return this.notAvailable
    }
    const major = (value >> 8) & 0xff
    const minor = value & 0xff

    return `${major}.${minor}`
  }

  public get notAvailable(){
    return this.locale.translate('general.not-available-short')
  }
}
