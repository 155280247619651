import { isTimeString, TimeString } from '_utils/timeUtils'
import { bindable } from 'aurelia-templating'

export class TimeField {
  //region Refs
  private inputRef!: HTMLInputElement
  //endregion

  //region Props
  @bindable name: string

  @bindable label: string

  @bindable value: TimeString

  @bindable readonly = false

  @bindable required = false

  /**
   *  Amount of seconds to step up and down. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#htmlattrdefstep).
   */
  @bindable step = 60
  //endregion

  private _inputValue: string

  private ignoreValueChanges: boolean

  private get doesSupportSeconds(): boolean {
    return this.step !== 60
  }

  private get inputValue() {
    return this._inputValue
  }

  /** Changed by the binding to the input field. Sets the internal value and then computes the exposed value */
  private set inputValue(it: string) {
    this._inputValue = it

    try {
      if (!isTimeString(it)) throw new Error()

      const splits = it.split(':')
      const hours = Number(splits[0]).toString().padStart(2, '0')
      const minutes = Number(splits[1]).toString().padStart(2, '0')
      const seconds = Number(splits[2]).toString().padStart(2, '0')

      this.inputRef.setCustomValidity('')
      this.internallyChangeValue(
        !this.doesSupportSeconds ? `${hours}:${minutes}` : `${hours}:${minutes}:${seconds}`
      )
    } catch {
      this.inputRef.setCustomValidity('invalid Time')
      this.internallyChangeValue('')
    }
  }

  private internallyChangeValue(newValue: string) {
    this.ignoreValueChanges = true
    this.value = newValue
    setImmediate(() => {
      this.ignoreValueChanges = false
    })
  }

  private valueChanged(newValue: string) {
    if (this.ignoreValueChanges) return

    if (!this.doesSupportSeconds && newValue.endsWith('00')) {
      this._inputValue = newValue.split(':').slice(0, 2).join(':')
    } else {
      this._inputValue = newValue
    }
  }
}
