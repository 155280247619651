import { inject } from 'aurelia-framework'
import { LocaleService } from 'services/locale-service'

@inject(LocaleService)
export class LanguageSelector {
  public languages: Array<{ key: string, value: string }>;
  private localeService: LocaleService;
  public selectedLanguage: string;

  constructor(localeService: LocaleService) {
    this.localeService = localeService
    
    this.languages = [
      { key: 'de', value: 'Deutsch' },
      { key: 'en', value: 'English' },
      // Add more languages here
    ]

    this.initializeLanguage()
  }


  private async initializeLanguage(): Promise<void> {
    // Ensure the locale is loaded first before fetching the language
    await this.localeService.loadLanguage()
    this.selectedLanguage = this.localeService.getLanguage() || 'en' 
  }

  public async changeLanguage(): Promise <void> {
    if (!this.selectedLanguage) return

    this.localeService.saveLanguage(this.selectedLanguage)
    await this.localeService.setLanguage(this.selectedLanguage)
    window.location.reload()
  }
}
