import { bindable, computedFrom, inject } from 'aurelia-framework'
import { DeviceStatus } from '../../../model/device-status'

import styles from './device-status-section-entry.module.scss'
import { LocaleService } from 'services/locale-service'
@inject(LocaleService)
export class DeviceStatusSectionEntry {
  styles = styles

  @bindable status: DeviceStatus


  constructor(private readonly localServ: LocaleService){}


  @computedFrom('status')
  get translatedStatus(){
    if (this.status.importance !== 'hidden') {
      return {
        ...this.status,
        description: (this.status.importance === 'ok' && this.status.description.startsWith('OK'))? this.status.description : this.localServ.translate(`devices.status.${this.status.description}`),
        label: this.localServ.translate(`devices.status.${this.status.label}`)
      }
    }
  
    return this.status 
  }
}
