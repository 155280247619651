import { computedFrom } from 'aurelia-binding'
import { inject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-templating'
import { LocaleService } from 'services/locale-service'

/**
 * A view that shows a readonly text.
 * @deprecated use KeyValueView instead
 */
@inject(LocaleService)
export class TextView {
  /**
   * Text label that is shown beside dropdown box. Bindable so the using page can set it.
   */
  @bindable public label = ''
  /**
   * current value that is shown in the view. Bindable so the using page can set it.
   */
  @bindable public value = ''
  /**
   * index of character where
   */
  @bindable public splitright?: number

  @bindable public separator = ''

  constructor(private readonly localeServ: LocaleService){

  }
  @computedFrom('value')
  public get leftPart(): string {
    if (!this.splitright || this.splitright < 0 || this.splitright > this.value.length - 1)
      return ''

    return this.value.substring(0, this.value.length - this.splitright)
  }

  @computedFrom('value')
  public get rightPart(): string {
    if (!this.splitright || this.splitright < 0 || this.splitright > this.value.length - 1)
      return this.localeServ.translate(this.value)

    return this.value.substring(this.value.length - this.splitright, this.value.length)
  }
  
}
