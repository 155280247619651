import { Router } from 'aurelia-router'

type ErrorDetails = {
  title: string
  message?: string
  fallbackRoute: string
  fallbackParams?: any
}

export type ErrorParams = Partial<ErrorDetails>

const defaultErrorDetails: ErrorDetails = {
  title: 'An error occured',
  fallbackRoute: 'home'
}

export class Error {
  public params: ErrorDetails

  constructor(private readonly router: Router) {}

  public activate(params: ErrorParams) {
    this.params = { ...defaultErrorDetails, ...params }
  }

  public onReturnButtonClicked() {
    this.router.navigateToRoute(this.params.fallbackRoute, this.params.fallbackParams)
  }
}
