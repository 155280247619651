import { mutateAllCaches } from '@nubix/npm-utils/src/cache/GlobalControl'
import { FacilityGroupApi } from '@nubix/spica-cloud-backend-client'
import { autoinject, useView } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { FacilityGroupDetails } from 'spica-cloud-shared/lib/model/facility-group'
import { ModalViewModel } from '../_controls/presentation/layout/modal-service'
import { BaseButton } from '../_controls/presentation/widget/base-button'
import { LocaleService } from 'services/locale-service'

@autoinject()
@useView(PLATFORM.moduleName('facility-groups/create-facility-group.html'))
export class CreateFacilityGroup implements ModalViewModel<undefined> {
  submitButton?: BaseButton
  details: FacilityGroupDetails 
  close: () => void

  constructor(private readonly facilityGroupApi: FacilityGroupApi, private readonly localServ:LocaleService) {
    this.details={
      name: this.localServ.translate('groups.new-grp')
    }
  }

  activate(props: { close: () => void }) {
    this.close = props.close
  }

  onSubmitClicked() {
    const progress = this.submit()
    this.submitButton?.displayProgress(progress)
  }

  async submit() {
    const requestProgress = this.facilityGroupApi.createGroup({ body: this.details })
    mutateAllCaches({ requestProgress })
    await requestProgress
    this.close()
  }
}
