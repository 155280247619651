import { IExtRouteConfig } from '_utils/routing'
import { autoinject } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { Router, RouterConfiguration } from 'aurelia-router'

/**
 * Index page of account pages. Defines routes of all account pages.
 */
@autoinject
export class App {
  public router: Router

  public configureRouter(config: RouterConfiguration, router: Router) {
    const routes: IExtRouteConfig[] = [
      // edit contact data
      {
        route: 'user-edit',
        name: 'user-edit',
        moduleId: PLATFORM.moduleName('account/contact/user-edit'),
        title: 'Edit personal infos',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      // edit login data
      {
        route: 'change-login',
        name: 'change-login',
        moduleId: PLATFORM.moduleName('account/contact/change-login'),
        title: 'Edit login',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      // show start page of account pages
      {
        route: ['', 'all-options'],
        name: 'all-options',
        moduleId: PLATFORM.moduleName('account/all-options'),
        title: 'Edit account',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      }
    ]

    config.map(routes)

    this.router = router
  }
}
