import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import {
  CircuitLabel,
  Cps,
  Facility,
  Fms,
  Gateway,
  Luminaire
} from '@nubix/spica-cloud-backend-client'
import { SseHandler } from '_utils/sseHandler'
import { compareText, concatText, containsTextToLower } from '_utils/text-utils'
import { computedFrom, observable } from 'aurelia-binding'
import { autoinject } from 'aurelia-dependency-injection'
import { I18N } from 'aurelia-i18n'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import { BindingSignaler } from 'aurelia-templating-resources'
import * as _ from 'lodash'
import { Device, getDeviceList } from 'model/device'
import { first } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { hideAll } from 'tippy.js'
import { doAfterNavigation, updateNavigationParams } from '../_utils/app-history/utils'
import { getData } from '../_utils/array-utils'
import { findNavigationToRoute } from '../_utils/routing'
import { reportErr } from '../errorReporting'
import { getFloorplanDownloadUrl } from '../main'
import { compareCircuitLabel, EMPTY_CIRCUIT_LABEL, matchCircuitLabel } from '../model/circuitLabel'
import { cpCpsFull, cpFmsFull, cpLuminaireFull } from '../model/utils'
import { CpsService } from '../services/cps-service'
import { FacilityService } from '../services/facility-service'
import { FmsService } from '../services/fms-service'
import { GatewayService } from '../services/gateway-service'
import { LuminaireService } from '../services/luminaire-service'

const LOG = getLogger('lumi-list')

const DEVICE_FILTER = ['name', 'circuit', 'message']

const DEVICE_TYPES: string[] = ['all', 'luminaire', 'fms', 'cps']

export type DeviceListParams = {
  id: string
  name?: string
  type?: string
  position?: string
  emergency?: string
  update?: string
  deviceFailure?: string
  connectFailure?: string
  testFailure?: string
  malfunction?: string
  sort?: string
  circuit?: CircuitLabel
}

/**
 * A route to display all luminaires, cps and fms for a certain facility
 */
@autoinject()
export class DeviceList extends subscriberMixin() {
  public readonly sortOptions: string[] = DEVICE_FILTER
  public readonly typeOptions: string[] = DEVICE_TYPES
  public readonly getLabelForSortingOption = (option: string) =>{
    return this.i18n.tr('devices.sorting.option', { context: option })
  }
  @observable({ changeHandler: 'navigationParamsChanged' })
  public sorting: string = DEVICE_FILTER[2]
  public filterCollapsed = true
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterName?: string
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterType?: string = DEVICE_TYPES[0]

  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterCircuit: CircuitLabel

  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterDeviceFailure?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterTestFailure?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterConnectFailure?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterEmergency?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterUpdate?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterMalfunction?: boolean

  public cachedLuminaires: Luminaire[] = []
  public cachedGateways: Gateway[] = []
  public cachedFms: Fms[] = []
  public cachedCps: Cps[] = []
  public cachedFacility: Facility
  @observable({ changeHandler: 'navigationParamsChanged' })
  public facilityID?: number

  private updateHandlerDev: SseHandler
  private updateHandlerFac: SseHandler

  private moduleId = '-'

  constructor(
    private readonly router: Router,
    private readonly authServ: AuthService,
    private readonly _facilityService: FacilityService,
    private readonly _cpsService: CpsService,
    private readonly _fmsService: FmsService,
    private readonly _luminaireService: LuminaireService,
    private readonly _gatewayService: GatewayService,
    private readonly signaler: BindingSignaler,
    private readonly i18n: I18N
  ) {
    super()
    setInterval(() => signaler.signal('ticktock-signal'), 5000)
  }

  @computedFrom('cachedFacility')
  get may() {
    return getPermissionTable(this.cachedFacility.myRole)
  }

  @computedFrom(
    'cachedLuminaires',
    'filterName',
    'filterType',
    'filterCircuit.powerDistributor',
    'filterCircuit.circuitNumber',
    'filterCircuit.luminaireNumber',
    'filterPosition',
    'filterDeviceFailure',
    'filterTestFailure',
    'filterConnectFailure',
    'filterEmergency',
    'filterUpdate',
    'filterMalfunction',
    'sorting'
  )
  get shownLuminaires(): Luminaire[] {
    if (!this.cachedLuminaires) return []
    if (!(this.filterType === 'all' || this.filterType === 'luminaire')) return []

    // filter luminaire list
    return this.cachedLuminaires
      .filter((it) => {
        return (
          !this.filterName || containsTextToLower(concatText(it.name, it.imsi), this.filterName)
        )
      })
      .filter((it) => matchCircuitLabel(this.filterCircuit, it.circuit))
      .filter((it) => {
        return (
          (!this.filterEmergency &&
            !this.filterDeviceFailure &&
            !this.filterTestFailure &&
            !this.filterMalfunction &&
            !this.filterUpdate &&
            !this.filterConnectFailure) ||
          (this.filterEmergency && !it.state.deactivated && !it.state.powerSupply) ||
          (this.filterUpdate && !it.state.deactivated && it.state.isUpdateNeeded) ||
          (this.filterDeviceFailure && !it.state.deactivated && it.state.failure.length > 0) ||
          (this.filterConnectFailure && !it.state.deactivated && !it.state.connected) ||
          (this.filterConnectFailure && it.state.definedOffline) ||
          (this.filterTestFailure &&
            !it.state.deactivated &&
            (it.state.lastFunctionTestResult === 'F' ||
              it.state.lastFunctionTestResult === 'X' ||
              it.state.lastDurationTestResult === 'F' ||
              it.state.lastDurationTestResult === 'X'))
        )
      })
      .sort((a, b) => {
        // Sorting
        switch (this.sorting) {
          case 'name':
            // we use 'z' to sort entries without name to the back of the list, 'special characters and 0-y' < 'z'
            return compareText(concatText(a.name, 'z', a.imsi), concatText(b.name, 'z', b.imsi))
          case 'message': {
            const diff = this.lumErrorPriority(b) - this.lumErrorPriority(a)
            if (diff !== 0) return diff
          }
          // tslint:disable-next-line:no-switch-case-fall-through
          // eslint-disable-next-line no-fallthrough
          case 'circuit': {
            const diff2 = compareCircuitLabel(a.circuit, b.circuit)
            if (diff2 !== 0) return diff2
          }
          // tslint:disable-next-line:no-switch-case-fall-through
          // eslint-disable-next-line no-fallthrough
          default:
            return compareText(a.imsi, b.imsi)
        }
      })
  }

  @computedFrom(
    'cachedCps',
    'filterName',
    'filterType',
    'filterDeviceFailure',
    'filterTestFailure',
    'filterConnectFailure',
    'filterEmergency',
    'filterMalfunction',
    'filterUpdate',
    'sorting'
  )
  get shownCps(): Cps[] {
    if (!this.cachedCps) return []
    if (!(this.filterType === 'all' || this.filterType === 'cps')) return []

    // filter luminaire list
    return this.cachedCps
      .filter((it) => {
        return (
          (!this.filterEmergency &&
            !this.filterConnectFailure &&
            !this.filterDeviceFailure &&
            !this.filterMalfunction &&
            !this.filterUpdate &&
            !this.filterTestFailure) ||
          (this.filterEmergency &&
            !it.state.deactivated &&
            it.config.enableBatteryActive &&
            it.state.batteryActive) ||
          (this.filterDeviceFailure &&
            !it.state.deactivated &&
            it.config.enableLuminaireFailure &&
            it.state.luminaireFailure) ||
          (this.filterMalfunction &&
            !it.state.deactivated &&
            it.config.enableMalfunction &&
            it.state.malfunction) ||
          (this.filterConnectFailure && !it.state.deactivated && !it.state.connected)
        )
      })
      .filter((it) => {
        return (
          !this.filterName || containsTextToLower(concatText(it.name, it.imsi), this.filterName)
        )
      })
      .sort((a, b) => {
        // Sorting
        switch (this.sorting) {
          case 'name':
            // we use 'z' to sort entries without name to the back of the list
            return compareText(concatText(a.name, 'z', a.imsi), concatText(b.name, 'z', b.imsi))
          case 'circuit':
            return compareText(a.imsi, b.imsi)
          case 'message':
            return this.cpsErrorPriority(b) - this.cpsErrorPriority(a)
          default:
        }

        return 0
      })
  }

  @computedFrom(
    'cachedFms',
    'filterName',
    'filterType',
    'filterDeviceFailure',
    'filterTestFailure',
    'filterConnectFailure',
    'filterEmergency',
    'filterUpdate',
    'filterMalfunction'
  )
  get shownFms(): Fms[] {
    if (!this.cachedFms) return []
    if (!(this.filterType === 'all' || this.filterType === 'fms')) return []

    // filter luminaire list
    return this.cachedFms
      .filter(() => {
        return (
          !this.filterDeviceFailure &&
          !this.filterTestFailure &&
          !this.filterConnectFailure &&
          !this.filterMalfunction &&
          !this.filterEmergency &&
          !this.filterUpdate
        )
      })
      .filter((it) => {
        return (
          !this.filterName || containsTextToLower(concatText(it.name, it.imsi), this.filterName)
        )
      })
  }

  @computedFrom('shownLuminaires', 'shownCps', 'shownFms', 'cachedGateways')
  get shownDevices() {
    return getDeviceList({
      luminaires: this.shownLuminaires,
      fmss: this.shownFms,
      cpss: this.shownCps,
      gateways: this.cachedGateways
    })
  }

  /**
   * On activation, load data from server and display it.
   * @param params - contains the id of the selected facility.
   */
  public async activate(params: DeviceListParams) {
    this.moduleId = '-'

    this.filterName = params.name
    this.filterCircuit = params.circuit ?? EMPTY_CIRCUIT_LABEL
    this.filterEmergency = params.emergency === 'true'
    this.filterUpdate = params.update === 'true'
    this.filterConnectFailure = params.connectFailure === 'true'
    this.filterTestFailure = params.testFailure === 'true'
    this.filterDeviceFailure = params.deviceFailure === 'true'
    this.filterMalfunction = params.malfunction === 'true'
    if (params.sort && this.sortOptions.indexOf(params.sort) > -1) {
      this.sorting = params.sort
    }
    if (params.type && this.typeOptions.indexOf(params.type) > -1) {
      this.filterType = params.type
    }

    if (!params.id) return doAfterNavigation(this.router, () => this.navigateUp())

    const facilityId = Number(params.id)
    const facility$ = this._facilityService.facilityEntityCache.get$(facilityId)
    const luminaires$ = this._luminaireService.luminaireFacilityCache.get$(facilityId)
    const cps$ = this._cpsService.cpsFacilityCache.get$(facilityId)
    const fms$ = this._fmsService.fmsFacilityCache.get$(facilityId)
    const gateway$ = this._gatewayService.gatewayQueryCache.get$({ facilityId: facilityId })

    this.subscribeUntilDeactivated({
      to: facility$,
      onNext: (it) => {
        if (it === undefined) {
          return doAfterNavigation(this.router, () => this.navigateUp())
        }

        this.cachedFacility = it
        this.facilityID = it.id

        if (!this.updateHandlerDev) {
          this.autorefreshDataDevices(this.cachedFacility)
        }
        if (!this.updateHandlerFac) {
          this.autorefreshDataFacility(this.cachedFacility)
        }
      }
    })

    this.subscribeUntilDeactivated({
      to: luminaires$,
      onNext: async (it) => {
        if (it === undefined) return

        this.cachedLuminaires = [...getData(await this._luminaireService.getEntities(it))]
      }
    })

    this.subscribeUntilDeactivated({
      to: cps$,
      onNext: async (it) => {
        if (it === undefined) return

        this.cachedCps = [...getData(await this._cpsService.getEntities(it))]
      }
    })

    this.subscribeUntilDeactivated({
      to: fms$,
      onNext: (it) => {
        if (it === undefined) return

        this.cachedFms = it
      }
    })

    this.subscribeUntilDeactivated({
      to: gateway$,
      onNext: (g) => (this.cachedGateways = g)
    })

    // wait for the first result
    await facility$.pipe(first()).toPromise()
    await luminaires$.pipe(first()).toPromise()
    await cps$.pipe(first()).toPromise()
    await fms$.pipe(first()).toPromise()
    await gateway$.pipe(first()).toPromise()
  }

  /**
   * On deactivation close all update SSE-connections to the server
   */
  public override deactivate() {
    super.deactivate()

    this.moduleId = '-'

    if (this.updateHandlerDev) {
      this.updateHandlerDev.close()
    }
    if (this.updateHandlerFac) {
      this.updateHandlerFac.close()
    }
  }

  /**
   * Navigate to the r
   * @param deviceType - the type of the device to add
   */
  public async onAddClicked(deviceType: 'luminaire' | 'fms' | 'cps') {
    await findNavigationToRoute(this.router, 'device-add', {
      facilityId: this.cachedFacility ? this.cachedFacility.id : undefined,
      deviceType
    })
  }

  /**
   * Hide all open popups
   */
  public onCancel() {
    hideAll()
  }

  /**
   * Navigate up to the facility-list
   */
  public async navigateUp() {
    return findNavigationToRoute(this.router, 'facilities')
  }

  /**
   * After Dom creation, initialize the actions dropdown.
   */
  public async attached() {
    this.moduleId = this.router.currentInstruction.config.moduleId ?? '-'
    this.navigationParamsChanged()
  }

  public toggleFilter() {
    this.filterCollapsed = !this.filterCollapsed
  }

  private lumErrorPriority(lumi: Luminaire): number {
    let prio = 0

    if (!lumi.state.deactivated) {
      prio++
      if (lumi.state.failure.length > 0) {
        prio += 100
      }
      if (lumi.state.lastFunctionTestResult === 'F' || lumi.state.lastFunctionTestResult === 'X') {
        prio += 200
      }
      if (lumi.state.lastDurationTestResult === 'F' || lumi.state.lastDurationTestResult === 'X') {
        prio += 1000
      }
      if (!lumi.state.blocked && !lumi.state.powerSupply) {
        prio += 2000
      }
      if (!lumi.state.connected) {
        prio += 100000
      }
      if (lumi.state.signal !== undefined && lumi.state.signal < 3) {
        prio += 30
      }
    }

    return prio
  }

  private cpsErrorPriority(cps: Cps): number {
    let prio = 0

    if (!cps.state.deactivated) {
      prio++
      if (cps.config.enableLuminaireFailure && cps.state.luminaireFailure) {
        prio += 200
      }
      if (cps.config.enableMalfunction && cps.state.malfunction) {
        prio += 100
      }
      if (cps.config.enableOperation && !cps.state.operation) {
        prio += 1000
      }
      if (cps.config.enableBatteryActive && cps.state.batteryActive) {
        prio += 2000
      }
      if (!cps.state.connected) {
        prio += 100000
      }
      if (cps.state.signal < 3) {
        prio += 30
      }
    }

    return prio
  }

  @computedFrom(
    'filterName',
    'filterType',
    'filterCircuit',
    'filterPosition',
    'filterDeviceFailure',
    'filterTestFailure',
    'filterConnectFailure',
    'filterEmergency',
    'filterUpdate',
    'filterMalfunction'
  )
  get filterCount(): number {
    let count = 0
    if (this.filterName) {
      count++
    }
    if (this.filterType !== 'all') {
      count++
    }
    if (this.filterCircuit.powerDistributor) count++
    if (this.filterCircuit.circuitNumber) count++
    if (this.filterCircuit.luminaireNumber) count++
    if (this.filterDeviceFailure) {
      count++
    }
    if (this.filterTestFailure) {
      count++
    }
    if (this.filterConnectFailure) {
      count++
    }
    if (this.filterEmergency) {
      count++
    }
    if (this.filterUpdate) {
      count++
    }
    if (this.filterMalfunction) {
      count++
    }

    return count
  }

  /**
   * Register an SSE-connection with the server and specify handlers for all update types
   */
  private autorefreshDataDevices(facility: Facility) {
    LOG.debug('start event tracking devices')
    const revalidateLumCache = _.debounce(() => {
      this._luminaireService.luminaireFacilityCache.revalidate(facility.id)
    }, 200)
    const listener4ChangeLum = async (event: any) => {
      await this.updateLumiSingle(event.data)
      this._luminaireService.luminaireFacilityCache.invalidate(facility.id)
    }
    const listener4MsgLum = async (event: any) => {
      const data = JSON.parse(event.data)
      await this.updateLumiSingle('' + data.id)
    }
    const revalidateFmsCache = _.debounce(() => {
      this._fmsService.fmsFacilityCache.revalidate(facility.id)
    }, 200)
    const listener4ChangeFms = async (event: any) => {
      await this.updateFmsSingle(event.data)
      this._fmsService.fmsFacilityCache.invalidate(facility.id)
    }
    const revalidateCpsCache = _.debounce(() => {
      this._cpsService.cpsFacilityCache.revalidate(facility.id)
    }, 200)
    const listener4ChangeCps = async (event: any) => {
      await this.updateCpsSingle(event.data)
      this._cpsService.cpsFacilityCache.invalidate(facility.id)
    }
    const listener4MsgCps = async (event: any) => {
      const data = JSON.parse(event.data)
      await this.updateCpsSingle(data.id)
    }

    this.updateHandlerDev = new SseHandler(
      `/events/devicesoffacility/${facility.id}`,
      this.authServ
    )
    this.updateHandlerDev.addEventListener('deletedluminaire', revalidateLumCache)
    this.updateHandlerDev.addEventListener('statusluminaire', listener4ChangeLum)
    this.updateHandlerDev.addEventListener('changeluminaire', listener4ChangeLum)
    this.updateHandlerDev.addEventListener('dataluminaire', listener4ChangeLum)
    this.updateHandlerDev.addEventListener('addluminaire', revalidateLumCache)
    this.updateHandlerDev.addEventListener('newmsgluminaire', listener4MsgLum)
    this.updateHandlerDev.addEventListener('newmsgcps', listener4MsgCps)
    this.updateHandlerDev.addEventListener('deletedfms', revalidateFmsCache)
    this.updateHandlerDev.addEventListener('statusfms', listener4ChangeFms)
    this.updateHandlerDev.addEventListener('datafms', listener4ChangeFms)
    this.updateHandlerDev.addEventListener('changefms', listener4ChangeFms)
    this.updateHandlerDev.addEventListener('addfms', revalidateFmsCache)
    this.updateHandlerDev.addEventListener('deletedcps', revalidateCpsCache)
    this.updateHandlerDev.addEventListener('statuscps', listener4ChangeCps)
    this.updateHandlerDev.addEventListener('datacps', listener4ChangeCps)
    this.updateHandlerDev.addEventListener('changecps', listener4ChangeCps)
    this.updateHandlerDev.addEventListener('addcps', revalidateCpsCache)
    this.updateHandlerDev.addEventListener('changegateway', () => {
      if (!this.facilityID) return
      this._gatewayService.gatewayQueryCache.invalidate({ facilityId: this.facilityID })
    })
    this.updateHandlerDev.onReconnect(() => {
      revalidateCpsCache()
      revalidateFmsCache()
      revalidateLumCache()
    })
    this.updateHandlerDev.connectStream()
  }

  /**
   * Register an SSE connection with the server and handle all updates related to a
   */
  private async autorefreshDataFacility(facility: Facility) {
    const listener4Del = (event: any) => {
      if (event.type === 'deletedfacility') {
        this.navigateUp()
      }
    }
    const revalidateCache = _.debounce(() => {
      this._facilityService.facilityEntityCache.revalidate(facility.id)
    }, 200)

    this.updateHandlerFac = new SseHandler(`/events/facility/${facility.id}`, this.authServ)
    this.updateHandlerFac.addEventListener('deletedfacility', listener4Del)
    this.updateHandlerFac.addEventListener('statusfacility', revalidateCache)
    this.updateHandlerFac.addEventListener('changefms', revalidateCache)
    this.updateHandlerFac.addEventListener('datafacility', revalidateCache)
    this.updateHandlerFac.onReconnect(revalidateCache)
    this.updateHandlerFac.connectStream()
  }

  /**
   * Update a luminaire in the cached list by reloading it from the server.
   *
   * @param id - the id of the luminaire to update
   */
  private async updateLumiSingle(id: string) {
    if (!this.facilityID) return

    const numId = parseInt(id, 10)
    this._luminaireService.luminaireEntityCache.invalidate(numId)
    const idx = this.indexOfLumi(numId)

    if (idx > -1) {
      const old = this.cachedLuminaires[idx]
      const update = await this._luminaireService.luminaireEntityCache
        .get(numId)
        .catch((e) => reportErr(e))
      if (update && update.location.facilityId === this.facilityID) {
        cpLuminaireFull(old, update)

        return
      }
    }

    this._luminaireService.luminaireFacilityCache.revalidate(this.facilityID)
  }

  /**
   * Update the fms by reloading it from the server
   * @param id - the id of the fms to update
   */
  private async updateFmsSingle(id: string) {
    if (!this.facilityID) return

    const numId = parseInt(id, 10)
    this._fmsService.fmsEntityCache.invalidate(numId)
    const idx = this.indexOfFms(numId)

    if (idx > -1) {
      const old = this.cachedFms[idx]
      const update = await this._fmsService.fmsEntityCache.get(numId).catch((e) => reportErr(e))
      if (update && update.location.facilityId === this.facilityID) {
        cpFmsFull(old, update)

        return
      }
    }

    this._fmsService.fmsFacilityCache.revalidate(this.facilityID)
  }

  /**
   * Update the cps by reloading it from the server
   * @param id - the id of the cps to update
   */
  private async updateCpsSingle(id: string) {
    if (!this.facilityID) return

    const numId = parseInt(id, 10)
    this._cpsService.cpsEntityCache.invalidate(numId)
    const idx = this.indexOfCps(numId)

    if (idx > -1) {
      const old = this.cachedCps[idx]
      const update = await this._cpsService.cpsEntityCache.get(numId).catch((e) => reportErr(e))
      if (update && update.location.facilityId === this.facilityID) {
        cpCpsFull(old, update)

        return
      }
    }

    this._cpsService.cpsFacilityCache.revalidate(this.facilityID)
  }

  /**
   * Get the index of a luminaire by it's id.
   *
   * @param numId - the id of the luminaire to locate
   */
  private indexOfLumi(numId: number): number {
    if (isNaN(numId) || numId <= 0) return -1

    return this.cachedLuminaires.findIndex((it) => numId === it.id)
  }

  /**
   * Get the index of a fms by it's id.
   *
   * @param numId - the id of the fms to locate
   */
  private indexOfFms(numId: number): number {
    if (isNaN(numId) || numId <= 0) return -1

    return this.cachedFms.findIndex((it) => numId === it.id)
  }

  /**
   * Get the index of a cps by it's id.
   *
   * @param numId - the id of the cps to locate
   */
  private indexOfCps(numId: number): number {
    if (isNaN(numId) || numId <= 0) return -1

    return this.cachedCps.findIndex((it) => numId === it.id)
  }

  /** Called when state changes that should be reflected in the url */
  private readonly navigationParamsChanged = () => {
    updateNavigationParams(
      this.router,
      {
        id: this.facilityID,
        name: this.filterName,
        circuit: this.filterCircuit,
        emergency: this.filterEmergency,
        update: this.filterUpdate,
        connectFailure: this.filterConnectFailure,
        testFailure: this.filterTestFailure,
        deviceFailure: this.filterDeviceFailure,
        malfunction: this.filterMalfunction,
        sort: this.sorting,
        type: this.filterType
      },
      this.moduleId
    )
  }

  onDeviceClick(device: Device) {
    const imsi = device.imsi

    switch (device.deviceType) {
      case 'luminaire':
        return findNavigationToRoute(this.router, 'luminaire-details', { imsi })
      case 'gateway':
        return findNavigationToRoute(this.router, 'gateway-details', { imsi })
      case 'fms':
        return findNavigationToRoute(this.router, 'fms-details', { imsi })
      case 'cps':
        return findNavigationToRoute(this.router, 'cps-details', { imsi })
    }
  }

  public onFloorplanClicked() {
    const fac = this.cachedFacility
    if (!fac) return

    if (fac.maps.floorPlanFile) {
      // hacked download link that works with IOS Chrome and Android app
      const link = document.createElement('a')

      link.href = getFloorplanDownloadUrl(
        fac.id,
        fac.maps.floorPlanFile,
        this.authServ.sessionToken
      )
      link.download = fac.maps.floorPlanFile
      link.target = '_blank'
      link.click()
    } else {
      findNavigationToRoute(this.router, 'facility-edit', {
        facilityId: fac.id,
        back: 'devices'
      })
    }
  }
}
