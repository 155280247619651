import { UserApi } from '@nubix/spica-cloud-backend-client'

import { HtmlValidationRenderer } from '_utils/validation/htmlValidationRenderer'
import { autoinject } from 'aurelia-dependency-injection'
import { Router } from 'aurelia-router'
import {
  validateTrigger,
  ValidationController,
  ValidationControllerFactory,
  ValidationRules
} from 'aurelia-validation'
import { findNavigationToRoute } from '../_utils/routing'
import { reportErr } from '../errorReporting'
import { I18N } from 'aurelia-i18n'

/**
 * A route to allow for the reset of a account-password via email
 */
@autoinject
export class ResetPassword {
  public userEmail: string

  public validationController: ValidationController

  public formRoot: HTMLFormElement

  constructor(
    private readonly router: Router,
    private readonly userApi: UserApi,
    readonly validationFactory: ValidationControllerFactory,
    private readonly i: I18N
  ) {
    this.validationController = validationFactory.createForCurrentScope()
    this.validationController.addRenderer(new HtmlValidationRenderer())
  }

  /**
   * Validate and submit the request to the server. Then navigate to the confirmation page.
   */
  public async nextStep() {
    this.validationController.reset()
    this.validationController.changeTrigger(validateTrigger.blur)
    ValidationRules.ensure('userEmail')
      .displayName('Email')
      .required()
      .email()
      .withMessage(this.i.tr('signup.account.emailValidationMessage'))

      .on(this)

    const result = await this.validationController.validate()

    if (result.valid) {
      this.userApi.resetPassword({ body: { email: this.userEmail } }).catch((e) => reportErr(e))

      findNavigationToRoute(this.router, 'confirm-email', { userEmail: this.userEmail }).catch(
        (e) => reportErr(e)
      )
    } else {
      this.formRoot.reportValidity()
    }
  }

  public async home() {
    return findNavigationToRoute(this.router, 'home')
  }
}
