import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { Cps } from '@nubix/spica-cloud-backend-client'
import { doAfterNavigation } from '_utils/app-history/utils'
import { HtmlValidationRenderer } from '_utils/validation/htmlValidationRenderer'
import { computedFrom } from 'aurelia-binding'

import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules
} from 'aurelia-validation'
import * as _ from 'lodash'
import { first } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { CpsService } from 'services/cps-service'
import { findNavigationToRoute } from '../_utils/routing'
import { SseHandler } from '../_utils/sseHandler'
import { Device } from '../model/device'
import { FacilityService } from '../services/facility-service'
import { LocaleService } from 'services/locale-service'

const LOG = getLogger('cps-details')

export interface ICpsDetailsParams {
  /**
   * Imsi of the cps to display
   */
  imsi?: string
}

/**
 * A route to display and edit configurations of a single CPS
 */
@autoinject()
export class CpsConfig extends subscriberMixin() {
  public cachedCps: Cps

  public formRoot: HTMLFormElement

  public cpsDetailsValidationController: ValidationController

  private updateHandler: SseHandler
  private updateHandlerFac: SseHandler
  private archivedCps: Cps

  @computedFrom('cachedCps')
  get device(): Device {
    return { deviceType: 'cps', ...this.cachedCps }
  }

  constructor(
    private readonly router: Router,
    private readonly authServ: AuthService,
    private readonly _cpsService: CpsService,
    private readonly _facilityService: FacilityService,
    private readonly validationFactory: ValidationControllerFactory,
    private readonly localServ: LocaleService
  ) {
    super()
    this.configureValidator()
  }

  /**
   * On activation, load and display information from the server and start autorefresh.
   * @param params - Parameters to be passed via the url of the page. See {@link ICpsDetailsParams}
   */
  public async activate(params: ICpsDetailsParams) {
    if (params.imsi === undefined) return doAfterNavigation(this.router, () => this.navigateUp())

    const cached = await this._cpsService.getEntityByImsi(params.imsi)

    if (!cached) {
      return doAfterNavigation(this.router, () => this.navigateUp())
    }

    const cps$ = this._cpsService.cpsEntityCache.get$(cached.id)

    this.subscribeUntilDeactivated({
      to: cps$,
      onNext: async (it) => {
        if (it === undefined) {
          return doAfterNavigation(this.router, () => this.navigateUp())
        }
        const cps = it

        this.updateFull(cps)

        // start event tracking
        if (!this.updateHandler) {
          this.autorefreshStatusCps(cps)
        }
        if (!this.updateHandlerFac) {
          this.autorefreshDataFacility(cps.location.facilityId)
        }
      }
    })

    // wait for the first result
    await cps$.pipe(first()).toPromise()
  }

  /**
   * On deactivation, close SSE connection to server
   */
  public override deactivate() {
    super.deactivate()

    if (this.updateHandler) {
      this.updateHandler.close()
    }
    if (this.updateHandlerFac) {
      this.updateHandlerFac.close()
    }
  }

  public async configureValidator() {
    this.cpsDetailsValidationController = this.validationFactory.createForCurrentScope()
    this.cpsDetailsValidationController.addRenderer(new HtmlValidationRenderer())
  }

  /**
   * Validate the form and submit changes to the server
   */
  public async onSave() {
    this.cpsDetailsValidationController.reset()
    ValidationRules.ensure('name')
      .displayName('Name')
      .maxLength(255)
      .withMessage('Maximal 255 Zeichen')
      .on(this.cachedCps)

    const validationResult = await this.cpsDetailsValidationController.validate()

    if (!validationResult.valid) {
      this.formRoot.reportValidity()
      throw new Error()
    }

    // send changed dat  to backend
    await this._cpsService.updateEntity(this.cachedCps).catch(() => {
      throw new Error(
        'Die Daten konnten nicht gespeichert werden. Bitte überprüfen Sie Ihre Eingaben.'
      )
    })
  }

  /**
   * Navigate to the cps details
   */
  public async navigateUp() {
    if (this.cachedCps && this.cachedCps.location.facilityId) {
      await findNavigationToRoute(this.router, 'cps-details', { imsi: this.cachedCps.imsi })
    } else {
      await findNavigationToRoute(this.router, 'facilities')
    }
  }

  /**
   * Register a SSE connection with the server and handle status updates
   */
  private autorefreshStatusCps(cps: Cps) {
    LOG.debug('start event tracking')

    const revalidateCache = _.debounce(() => {
      this._cpsService.cpsEntityCache.revalidate(cps.id)
      this._cpsService.cpsFacilityCache.invalidate(cps.location.facilityId)
    }, 200)

    this.updateHandler = new SseHandler(`/events/cps/${cps.id}`, this.authServ)
    this.updateHandler.addEventListener('deletedcps', () => this.navigateUp())
    this.updateHandler.addEventListener('statuscps', revalidateCache)
    this.updateHandler.addEventListener('changecps', revalidateCache)
    this.updateHandler.addEventListener('datacps', revalidateCache)
    this.updateHandler.onReconnect(revalidateCache)
    this.updateHandler.connectStream()
  }

  /**
   * Register an SSE connection with the server and handle all updates related to a
   */
  private async autorefreshDataFacility(facilityId: number) {
    const listener4Del = (event: any) => {
      if (event.type === 'deletedfacility') {
        this.navigateUp()
      }
    }
    const revalidateCache = _.debounce(() => {
      this._facilityService.facilityEntityCache.invalidate(facilityId)
    }, 200)

    this.updateHandlerFac = new SseHandler(`/events/facility/${facilityId}`, this.authServ)
    this.updateHandlerFac.addEventListener('deletedfacility', listener4Del)
    this.updateHandlerFac.addEventListener('statusfacility', revalidateCache)
    this.updateHandlerFac.addEventListener('changefms', revalidateCache)
    this.updateHandlerFac.addEventListener('datafacility', revalidateCache)
    this.updateHandlerFac.onReconnect(revalidateCache)
    this.updateHandlerFac.connectStream()
  }

  /**
   * Update all shown information with data from backend. Might trigger a visible refresh of the page. Will
   * refresh data in editable input fields too. Will reset dirty state.
   */
  private async updateFull(update?: Cps) {
    if (!update) return

    // we have to copy it
    this.cachedCps = JSON.parse(JSON.stringify(update))

    this.archivedCps = update
  }
}
