import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { mutateAllCaches } from '@nubix/npm-utils/src/cache/GlobalControl'
import {
  ContactApi,
  FacilityGroup,
  FacilityGroupApi,
  User
} from '@nubix/spica-cloud-backend-client'
import { autoinject, computedFrom, useView, view } from 'aurelia-framework'
import { Router } from 'aurelia-router'
import { firstValueFrom } from 'rxjs'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { ModalViewModel } from '../_controls/presentation/layout/modal-service'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { FacilityGroupId, FacilityGroupService } from '../services/facility-group-service'
import { DeleteFacilityGroupParams } from './delete-facility-group'
import { I18N } from 'aurelia-i18n'

interface Props {
  id: FacilityGroupId
}

@useView('facility-groups/edit-facility-group.html')
@autoinject()
export class EditFacilityGroup extends subscriberMixin() implements ModalViewModel<Props> {
  detailsForm?: HTMLFormElement

  details: { name: string } = { name: '' }

  close: () => void

  @computedFrom('facilityGroup', 'details.name')
  get name() {
    if (this.details.name) return this.details.name
    return this.facilityGroup?.name ?? ''
  }

  set name(value: string) {
    this.details.name = value
  }

  @computedFrom('facilityGroup', 'details')
  get isDirty() {
    return this.facilityGroup?.name !== name
  }

  @computedFrom('facilityGroup')
  get may() {
    return getPermissionTable(this.facilityGroup?.myRole)
  }

  facilityGroup?: FacilityGroup
  currentUser?: User

  constructor(
    private readonly facilityGroupService: FacilityGroupService,
    private readonly facilityGroupApi: FacilityGroupApi,
    private readonly contactApi: ContactApi,
    private readonly router: Router,
    private readonly i18n: I18N
  ) {
    super()
  }

  async submit(): Promise<ActionResult> {
    if (!this.facilityGroup) throw new Error()
    if (!this.detailsForm?.checkValidity()) {
      this.detailsForm?.reportValidity()
      return { hasError: true }
    }

    const requestProgress = this.facilityGroupApi.updateGroup({
      id: this.facilityGroup.id,
      body: { name: this.name }
    })
    this.facilityGroupService.facilityGroupEntityCache.mutate({
      requestProgress,
      where: (key, _) => key === this.facilityGroup?.id
    })
    this.facilityGroupService.facilityGroupQueryCache.mutate({ requestProgress })
    await requestProgress
  }

  async activate(props: Props & { close: () => void }) {
    const facilityGroup$ = this.facilityGroupService.facilityGroupEntityCache.get$(props.id)
    this.assignWithLifecycle(this, facilityGroup$, 'facilityGroup')
    await firstValueFrom(facilityGroup$)
    this.close = props.close
  }

  async leave(group: FacilityGroup) {
    const requestProgress = this.contactApi.leaveCompany({ companyId: group.id })
    mutateAllCaches({ requestProgress })
    await requestProgress
    this.close()
  }

  delete(group: FacilityGroup) {
    const params: DeleteFacilityGroupParams = { id: group.id }
    this.router.navigateToRoute('delete-facility-group', params)
    this.close()
  }
}
