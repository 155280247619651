import { computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-framework'
import { bindable } from 'aurelia-templating'
import { AureliaComponent } from '../../../_utils/AureliaComponent'
import { LocaleService } from 'services/locale-service'

@autoinject()
export class RouteLayout extends AureliaComponent {
  @bindable backClick?: () => void

  @bindable backLabel = 'Back'

  @bindable narrow = false

  constructor(element: Element, private readonly localServ : LocaleService) {
    super(element)
  }



  @computedFrom('usedSlots')
  private get hasFilter() {
    return this.usedSlots.includes('filter')
  }

  @computedFrom('usedSlots')
  private get hasHeader() {
    return this.usedSlots.includes('header')
  }

  @computedFrom('usedSlots')
  private get hasAside() {
    return this.usedSlots.includes('aside')
  }
}
