import { NotificationFrequency, SubscriptionGroup } from '@nubix/spica-cloud-backend-client'
import { computedFrom } from 'aurelia-binding'
import { inject } from 'aurelia-dependency-injection'
import { bindable, containerless } from 'aurelia-templating'
import { LocaleService } from 'services/locale-service'

const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const AVAILABLE_FREQUENCIES: { index: NotificationFrequency; description: string }[] = [
  { index: 'immediate', description: 'immediate' },
  { index: 'daily', description: 'daily' },
  { index: 'weekly', description: 'weekly' }
]

@containerless()
@inject(LocaleService)
export class SubscriptionGroupField {
  @bindable() readonly = false
  @bindable() workingCopy?: SubscriptionGroup
  @bindable() remoteState: SubscriptionGroup
  @bindable() label: string
  @bindable() suggestions: string[]


  constructor(private readonly localServ:LocaleService){}

  @computedFrom('workingCopy.frequency', 'remoteState.frequency')
  get mayCauseImmediateNotification() {
    if (!this.workingCopy?.frequency) return false
    const wasChanged = this.workingCopy.frequency !== this.remoteState.frequency
    return wasChanged && this.workingCopy.frequency === 'immediate'
  }
  public readonly getTranslatedHelp =()=>{
    return this.localServ.translate('notifs.email-placeholder')
  }

  availableFrequencies = AVAILABLE_FREQUENCIES
  translatedFrequencies = this.getTranslatedFreq()


  
  isEmailPattern(value: string): boolean {
    return EMAIL_REGEX.test(value)
  }


  getTranslatedFreq() {
    return this.availableFrequencies.map(freq=> 
      freq.description = this.localServ.translate(`notifs.frequencies.${freq.index}`))
      
  };
  

}
