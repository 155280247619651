import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { Facility, FacilityGroup } from '@nubix/spica-cloud-backend-client'
import { ContactInvitationWithCompany } from '@nubix/spica-cloud-backend-client/models'
import { updateNavigationParams } from '_utils/app-history/utils'
import { SseHandler } from '_utils/sseHandler'
import { compareText, concatText, containsTextToLower } from '_utils/text-utils'
import { computedFrom, observable } from 'aurelia-binding'

import { autoinject } from 'aurelia-dependency-injection'
import { I18N } from 'aurelia-i18n'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import * as _ from 'lodash'
import { first } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { FacilityService } from 'services/facility-service'
import { getPermissionTable, PermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { ModalService } from '../_controls/presentation/layout/modal-service'
import { isSuccess, loadingResult, Result, successResult, toResult } from '../_utils/FormCache'
import { findNavigationToRoute } from '../_utils/routing'
import { CreateFacilityGroup } from '../facility-groups/create-facility-group'
import { editFacilityGroupModal } from '../facility-groups/edit-facility-group-modal'
import { ContactInvitationService } from '../services/contact-invitation-service'
import { FacilityGroupService } from '../services/facility-group-service'
import { IAddFacilityParams } from './facility-add'

import styles from './facility-list.module.scss'

const LOG = getLogger('facility-list')

const FACILITY_FILTER: string[] = ['name', 'address', 'message']

export type FacilityGroupWithFacilities = {
  details: FacilityGroup
  facilities: Facility[]
  may: PermissionTable
}

/**
 * This route displays all facilities associated with the current user.
 */
@autoinject()
export class FacilityList extends subscriberMixin() {
  styles = styles

  public readonly sortOptions: string[] = FACILITY_FILTER
  @observable({ changeHandler: 'navigationParamsChanged' })
  public sorting: string = FACILITY_FILTER[2]
  public readonly getLabelForSortingOption = (option: string) =>
    this.i18n.tr('facility.sorting.option', { context: option })
  public filterCollapsed = true
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterName?: string
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterStreet?: string
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterCity?: string
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterManager?: string
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterEmergency?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterUpdate?: boolean
  @observable({ changeHandler: 'navigationParamsChanged' })
  public filterError?: boolean

  facilities: Result<Facility[]> = loadingResult()
  facilityGroups: Result<FacilityGroup[]> = loadingResult()
  contactInvitations: Result<ContactInvitationWithCompany[]> = loadingResult()

  private updateHandler: SseHandler

  private moduleId = '-'

  constructor(
    public readonly router: Router,
    private readonly authServ: AuthService,
    private readonly _facilityGroupService: FacilityGroupService,
    private readonly _facilityService: FacilityService,
    private readonly _contactInvitationService: ContactInvitationService,
    private readonly _modalService: ModalService,
    private readonly i18n: I18N
  ) {
    super()
  }

  /**
   * Provides access to the stored facilities for the HTML page (aurelia binding)
   */
  @computedFrom(
    'facilities',
    'filterName',
    'filterStreet',
    'filterCity',
    'filterManager',
    'filterEmergency',
    'filterUpdate',
    'filterError',
    'sorting'
  )
  get filteredFacilities(): Result<Facility[]> {
    if (!isSuccess(this.facilities)) return loadingResult()
    const facilities = this.facilities.value

    // filter facility list
    const filtered = facilities
      .filter((it) => {
        return !this.filterName || containsTextToLower(it.name, this.filterName)
      })
      .filter((it) => {
        return (
          !this.filterStreet ||
          containsTextToLower(
            concatText(it.address.street, it.address.streetNumber),
            this.filterStreet
          )
        )
      })
      .filter((it) => {
        return (
          !this.filterCity ||
          containsTextToLower(
            concatText(it.address.city, it.address.zipCode, it.address.country),
            this.filterCity
          )
        )
      })
      .filter((it) => {
        return (
          !this.filterManager ||
          containsTextToLower(
            concatText(
              it.manager.firstName,
              it.manager.lastName,
              it.manager.email,
              it.manager.phone
            ),
            this.filterManager
          )
        )
      })
      .filter((it) => {
        return (
          (!this.filterError && !this.filterEmergency && !this.filterUpdate) ||
          (this.filterEmergency && !it.state.deactivated && it.state.emergency) ||
          (this.filterUpdate && !it.state.deactivated && it.state.availableFwUpdate) ||
          (this.filterError &&
            !it.state.deactivated &&
            (it.state.connectionFailure || it.state.failure))
        )
      })
      .sort((a, b) => {
        switch (this.sorting) {
          case 'message': {
            const diff = this.errorPriority(b) - this.errorPriority(a)
            if (diff !== 0) return diff
          }
          // tslint:disable-next-line:no-switch-case-fall-through
          // eslint-disable-next-line no-fallthrough
          case 'name': {
            const diff2 = compareText(a.name, b.name)
            if (diff2 !== 0) return diff2
          }
          // tslint:disable-next-line:no-switch-case-fall-through
          // eslint-disable-next-line no-fallthrough
          case 'address':
            return compareText(this.addressToString(a), this.addressToString(b))
          default:
        }

        return 0
      })

    return successResult(filtered)
  }

  @computedFrom('facilityGroups', 'filteredFacilities', 'roles', 'filterCount')
  get facilityGroupsWithFacilities(): Result<FacilityGroupWithFacilities[]> {
    const { facilityGroups, filteredFacilities, filterCount } = this

    if (!isSuccess(facilityGroups)) return facilityGroups
    if (!isSuccess(filteredFacilities)) return filteredFacilities

    const facilityGroupsWithFacilities: FacilityGroupWithFacilities[] = facilityGroups.value.map(
      (facilityGroup) => ({
        details: facilityGroup,
        facilities: filteredFacilities.value.filter((f) => f.companyId === facilityGroup.id),
        may: getPermissionTable(facilityGroup.myRole)
      })
    )

    if (!filterCount) return successResult(facilityGroupsWithFacilities)

    // If a filter is set, we hide the companies that don't contain any results
    return successResult(facilityGroupsWithFacilities.filter((f) => f.facilities.length))
  }

  @computedFrom(
    'filterName',
    'filterStreet',
    'filterCity',
    'filterManager',
    'filterEmergency',
    'filterUpdate',
    'filterError'
  )
  get filterCount(): number {
    let count = 0
    if (this.filterName) {
      count++
    }
    if (this.filterStreet) {
      count++
    }
    if (this.filterCity) {
      count++
    }
    if (this.filterManager) {
      count++
    }
    if (this.filterError) {
      count++
    }
    if (this.filterEmergency) {
      count++
    }
    if (this.filterUpdate) {
      count++
    }

    return count
  }

  /**
   * On open, load Information from server and start autorefresh
   */
  public async activate(params: {
    name?: string
    street?: string
    city?: string
    manager?: string
    emergency?: string
    update?: string
    error?: string
    sort?: string
  }) {
    this.moduleId = '-'

    this.filterName = params.name
    this.filterCity = params.city
    this.filterStreet = params.street
    this.filterManager = params.manager
    this.filterEmergency = params.emergency === 'true'
    this.filterUpdate = params.update === 'true'
    this.filterError = params.error === 'true'
    if (params.sort && this.sortOptions.indexOf(params.sort) > -1) {
      this.sorting = params.sort
    }

    const facilities$ = this._facilityService.facilityQueryCache.get$(undefined)
    const facilityGroups$ = this._facilityGroupService.facilityGroupQueryCache.get$(undefined)
    const invitations$ = this._contactInvitationService.getIncomingInvitationsWithRemoteUpdates()

    this.subscribeUntilDeactivated({
      to: facilityGroups$.pipe(toResult()),
      onNext: (v) => (this.facilityGroups = v)
    })

    this.subscribeUntilDeactivated({
      to: facilities$.pipe(toResult()),
      onNext: (v) => {
        this.facilities = v
        if (!this.updateHandler) this.autorefreshDataFacilities()
      }
    })

    this.subscribeUntilDeactivated({
      to: invitations$.pipe(toResult()),
      onNext: (v) => (this.contactInvitations = v)
    })

    // wait for the first result
    await facilities$.pipe(first()).toPromise()
  }

  /**
   * On deactivation, close SSE session with the server
   */
  public override deactivate() {
    super.deactivate()

    this.moduleId = '-'

    if (this.updateHandler) {
      this.updateHandler.close()
    }
  }

  /**
   * After Dom instantiation, register the account-tooltip
   */
  public attached() {
    this.moduleId = this.router.currentInstruction.config.moduleId ?? '-'
    this.navigationParamsChanged()
  }

  public editFacilityGroup(group: FacilityGroupWithFacilities) {
    const translatedTitle = this.i18n.tr('groups.edit.edit-title')
    this._modalService.showModal(editFacilityGroupModal({ id: group.details.id }, translatedTitle))
  }

  public createFacilityGroup() {
    this._modalService.showModal({ viewModel: CreateFacilityGroup, model: undefined })
  }

  /**
   * Navigate to route-creation screen
   */
  public async onAddClicked(FacilityGroup: FacilityGroup) {
    const params: IAddFacilityParams = { groupId: FacilityGroup.id.toString() }
    await findNavigationToRoute(this.router, 'facility-add', params)
  }

  /**
   * Open the luminaire-list for the clicked facility
   *
   * @param facilityId - id of the clicked facility
   */
  public async onCardClicked(facilityId: number) {
    return findNavigationToRoute(this.router, 'devices', {
      id: facilityId,
      emergency: this.filterEmergency,
      update: this.filterUpdate,
      testFailure: this.filterError,
      deviceFailure: this.filterError,
      connectFailure: this.filterError
    })
  }

  /**
   * Navigate to account settings for the current user
   */
  public async onAccountClicked() {
    await findNavigationToRoute(this.router, 'account')
  }

  /**
   * Log out
   */
  public async onLogoutClicked() {
    await findNavigationToRoute(this.router, 'logout')
  }

  public toggleFilter() {
    this.filterCollapsed = !this.filterCollapsed
  }

  private addressToString(fac: Facility): string {
    return concatText(
      fac.address.country,
      fac.address.zipCode,
      fac.address.city,
      fac.address.street,
      fac.address.streetNumber
    )
  }

  private errorPriority(fac: Facility): number {
    let prio = 0

    if (!fac.state.deactivated) {
      prio++
      if (fac.state.failure) {
        prio += 1000
      }
      if (fac.state.connectionFailure) {
        prio += 2000
      }
      if (!fac.state.emergencyBlocked && fac.state.emergency) {
        prio += 5000
      }
    }

    return prio
  }

  /**
   * Open SSE connection with server
   */
  private autorefreshDataFacilities() {
    LOG.debug('start event tracking')

    const revalidateCache = _.debounce(() => {
      this._facilityService.facilityEntityCache.revalidateAll()
      this._facilityService.facilityQueryCache.revalidateAll()
      // this._facilityService.facilityQueryCache.revalidate(undefined)
    }, 200)
    const listener4ChangeFaci = async () => {
      this._facilityService.facilityEntityCache.revalidateAll()
      this._facilityService.facilityQueryCache.revalidateAll()
    }
    this.updateHandler = new SseHandler('/events/facilities/', this.authServ)
    this.updateHandler.addEventListener('deletedfacility', revalidateCache)
    this.updateHandler.addEventListener('statusfacility', listener4ChangeFaci)
    this.updateHandler.addEventListener('changefacility', listener4ChangeFaci)
    this.updateHandler.addEventListener('datafacility', listener4ChangeFaci)
    this.updateHandler.addEventListener('addfacility', revalidateCache)
    this.updateHandler.addEventListener('changecompanyfactories', listener4ChangeFaci)
    this.updateHandler.onReconnect(revalidateCache)
    this.updateHandler.connectStream()
  }

  /** Called when state changes that should be reflected in the url */
  private readonly navigationParamsChanged = () => {
    updateNavigationParams(
      this.router,
      {
        name: this.filterName,
        city: this.filterCity,
        street: this.filterStreet,
        manager: this.filterManager,
        emergency: this.filterEmergency,
        update: this.filterUpdate,
        error: this.filterError,
        sort: this.sorting
      },
      this.moduleId
    )
  }
}
