import { HttpResponseMessage } from 'aurelia-http-client'
import { ErrorHandler } from './ErrorHandler'
import { I18N } from 'aurelia-i18n'

export const handleConnectionError: ErrorHandler = (e, i18n:I18N) => {
  if (!(e instanceof HttpResponseMessage) || e.statusCode !== 0) return undefined
  return {
    errorMessage:i18n.tr('error:auth.server-not-reachable')
   
  }
}
