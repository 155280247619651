export const DEFAULT_COUNTRY = 'DE'


//DE Germany
//AT Austria 
export const SUPPORTED_COUNTRYS = [DEFAULT_COUNTRY, 'AT']

export const SUPPORTED_LANGUAGES = ['en', 'de']
export const SUPPORTED_SOCIALFORMS = [
  '-',
  'GbR',
  'OHG',
  'UG',
  'KG',
  'KGaA',
  'AG',
  'PartG',
  'GmbH',
  'GmbH & Co. KG',
  'Stille Gesellschaft',
  'Stiftung',
  'Genossenschaft',
  'VVaG'
]

export const SUPPORTED_TITLES = ['', 'professor', 'doctor']

export const SUPPORTED_ADDRESSFORMS = ['mr', 'ms']
