import { Facility, FacilityApi, FacilityGroup } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { Router } from 'aurelia-router'
import { SUPPORTED_COUNTRYS } from 'localization'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { findNavigationToRoute } from '../_utils/routing'
import { FacilityGroupService } from '../services/facility-group-service'
import { FacilityService } from '../services/facility-service'
import { LocaleService } from 'services/locale-service'

export interface IAddFacilityParams {
  /**
   * The parent company for the newly created facility.
   */
  groupId: string
}

/**
 * This Page allows the User to create a new Facility
 */
@autoinject()
export class AddFacility {
  public _cachedFacilityGroups: FacilityGroup[]
  public countryOptions = SUPPORTED_COUNTRYS
  public groupId: number

  public formRoot: HTMLFormElement

  private _cachedFacility: Facility

  public constructor(
    private readonly router: Router,
    private readonly _facilityGroupService: FacilityGroupService,
    private readonly _facilitySerice: FacilityService,
    private readonly _facilityApi: FacilityApi,
    private readonly localServ: LocaleService
  ) {}

  /**
   * Provides access to the stored facility for the HTML page (aurelia binding)
   */
  get details() {
    return this._cachedFacility
  }

  public readonly getTranslatedCountries = (options:[])=> 
    options.map(coutry=> this.localServ.translate(`countries.${coutry}`))
    

  /**
   * Load company information from server and display an empty facility-creation form.
   * @param params - Parameters to be passed via the url of the page. See {@link IAddFacilityParams}
   */
  public async activate(params: IAddFacilityParams) {
    this.groupId = parseInt(params.groupId, 10)
    this._cachedFacilityGroups = await this._facilityGroupService.facilityGroupQueryCache.get(
      undefined
    )

    // create new fresh facility record so user can edit it
    this._cachedFacility = {
      id: 0,
      companyId: this.groupId || this._cachedFacilityGroups[0].id,
      name: '',
      address: {
        street: '',
        streetNumber: '',
        zipCode: '',
        city: '',
        country: this.countryOptions[0]
      },
      manager: {
        contactId: 0
      },
      state: {
        emergencyBlocked: false,
        manualBlocked: false,
        deactivated: false,
        failure: false,
        emergency: false,
        connectionFailure: false,
        availableFwUpdate: false
      },
      maps: {},
      myRole: 'ADMIN' // added to satisfy typescript and tsoa validation. IMPROVE: Only require actually necessary data in FacilityController
    }
  }

  /**
   * Navigate back to facility-list
   */
  public navigateUp() {
    findNavigationToRoute(this.router, 'facilities').catch(async () => undefined)
  }

  /**
   * Submit the facility to the server and return to the facility list if successful.
   */
  public async onSave(): Promise<ActionResult> {
    if (!this.formRoot.checkValidity()) {
      this.formRoot.reportValidity()
      return { hasError: true }
    }

    const requestProgress = this._facilityApi.addFacility({ body: this._cachedFacility })
    await this._facilitySerice.facilityEntityCache.mutate({ requestProgress })

    this.navigateUp()
  }
}
