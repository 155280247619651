import { inject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-templating'
import { Device } from 'model/device'
import { LocaleService } from 'services/locale-service'


@inject(LocaleService)
export class DeviceDetails {
  @bindable() device: Device
  @bindable() readonly = true


  constructor( private readonly localeServ: LocaleService){}



  public get translatedExitSign(){
    return this.localeServ.translate('devices.exit-sign')
  }
  public get translatedSafetyLight(){
    return this.localeServ.translate('devices.safety-light')
  }
}
