import { ModalDefinition, ViewModelProps } from '../_controls/presentation/layout/modal-service'
import { EditFacilityGroup } from './edit-facility-group'

type Props = ViewModelProps<EditFacilityGroup>

export function editFacilityGroupModal(model: Props, title: string): ModalDefinition<Props> {
  return {
    viewModel: EditFacilityGroup,
    titleBar: { title: title },
    desiredWidth: 'wide',
    model
  }
}
