import { Cps, Luminaire } from '@nubix/spica-cloud-backend-client'
import { hasDefined } from '../_utils/objectUtils'
import {
  Device,
  FmsDevice,
  GatewayConnectedDevice,
  GatewayDevice,
  isGatewayConnectedDevice,
  isSpicaConnectedDevice,
  LuminaireDevice,
  SpicaConnectedDevice
} from './device'

/** Represents a status of a device. Displayed in one of the device-detail pages and in the device-card */
export type DeviceStatus =
  | {
      importance: Importance
      label: string
      faIcon: string
      description: string
    }
  | {
      /** The status is hidden, both from the card and the details page */
      importance: 'hidden'
    }

type Importance =
  | 'normal' // Show only in DeviceStatusSection
  | 'hidden' // Don't show, even in DeviceStatusSection
  | 'important' // Show as black, including in DeviceCard and header
  | 'ok' // Show in the DeviceStatusSection as green, don't show in DeviceCard and header
  | 'error' // Show as red, including in DeviceCard and header
  | 'warn' // Show as yellow, including in DeviceCard and header

//region ConnectionStatus
export function getConnectionStatus(d: Device): DeviceStatus {
  const base = { label: 'connection' } as const
  if (d.state.deactivated)
    return { ...base, faIcon: 'ban', description: 'disabled', importance: 'normal' } as const

  if (isSpicaConnectedDevice(d)) return { ...base, ...getSpicaSignalStrength(d) }

  if (isGatewayConnectedDevice(d)) {
    const pairStatus = getGatewayPairStatus(d)
    if (pairStatus) return { ...base, ...pairStatus }
  }

  return {
    ...base,
    faIcon: 'question',
    description: 'connect-stat-unknown',
    importance: 'error'
  }
}

export function getGatewayPairStatus(d: GatewayConnectedDevice) {
  const p = d.pairedGateway?.isPaired

  if (!p)
    return {
      faIcon: '',
      description: 'gateway-no-connect',
      importance: 'normal'
    } as const

  if (!p.value && p.isConfirmed)
    return {
      faIcon: 'spinner-third fa-spin',
      description: 'gateway-disconnect-running',
      importance: 'normal'
    } as const

  if (p.value && !p.isConfirmed)
    return {
      faIcon: 'spinner-third fa-spin',
      description: 'gateway-connect-running',
      importance: 'normal'
    } as const

  if (!d.state.connected)
    return {
      faIcon: 'circle-nodes',
      description: 'connection-fault',
      importance: 'error'
    } as const

  if (p.value && p.isConfirmed)
    return {
      faIcon: 'circle-nodes',
      description: 'gateway-connected',
      importance: 'normal'
    } as const

  return {
    faIcon: '',
    description: 'gateway-not-connected',
    importance: 'normal'
  } as const
}

export function getSpicaSignalStrength(d: SpicaConnectedDevice) {
  const s = d.state.signal

  if ('definedOffline' in d && d.definedOffline)
    return {
      faIcon: 'signal-slash',
      importance: 'normal',
      description: 'tmp-offline'
    } as const

  if (!s || !d.state.connected || s > 32)
    return {
      faIcon: 'signal-slash',
      description: 'connection-fault',
      importance: 'error'
    } as const
  if (s <= 2)
    return { faIcon: 'signal-weak', description: `OK (${s}/32)`, importance: 'ok' } as const
  if (s <= 8)
    return { faIcon: 'signal-fair', description: `OK (${s}/32)`, importance: 'ok' } as const
  if (s <= 14)
    return { faIcon: 'signal-good', description: `OK (${s}/32)`, importance: 'ok' } as const
  if (s <= 20)
    return { faIcon: 'signal-strong', description: `OK (${s}/32)`, importance: 'ok' } as const

  return { faIcon: 'signal-perfect', description: `OK (${s}/32)`, importance: 'ok' } as const
}

//endregion

function getBatteryDeviceStatus(state: { powerSupply: boolean }): DeviceStatus {
  const base = { label: 'power' }
  if (state.powerSupply)
    return {
      ...base,
      faIcon: 'plug',
      importance: 'ok',
      description: 'grid-connection'
    } as const
  return {
    ...base,
    faIcon: 'battery-exclamation',
    importance: 'warn',
    description: 'battery'
  } as const
}

export function getInspectionStatus(d: Luminaire): DeviceStatus {
  const base = { label: 'test' }
  if (d.state.lastFunctionTestResult === 't' || d.state.lastDurationTestResult === 't')
    return {
      ...base,
      faIcon: 'clipboard-list',
      description:
        'tests-fail',
      importance: 'warn'
    } as const
  return { ...base, importance: 'hidden' } as const
}

function getFailureStatus(dev: LuminaireDevice | GatewayDevice): DeviceStatus {
  const hasBattFailure = dev.state.failure.some((it) =>
    [
      'BATTERYCAPACITY',
      'BATTERYCRITICAL',
      'BATTERYFAILURE',
      'BATTERYMISSING',
      'BATTERYSHORTED'
    ].includes(it)
  )

  const hasLedFailure = dev.state.failure.some((it) =>
    ['LEDFAILURE', 'LEDMISSING', 'LEDINCOMPATIBLE', 'LEDSHORTED'].includes(it)
  )

  const hasTestFailure = dev.state.failure.some((it) => it === 'TESTFAILURE')

  const description =
    hasBattFailure && hasLedFailure
      ? 'complete-breakdown'
      : hasBattFailure && !hasLedFailure
      ? 'battery-fault'
      : !hasBattFailure && hasLedFailure
      ? 'lum-fail'
      : hasTestFailure
      ? 'test-fail'
      : undefined

  const base = { label: 'health' }
  if (description)
    return {
      ...base,
      faIcon: 'triangle-exclamation',
      importance: 'error',
      description
    } as const
  return {
    ...base,
    faIcon: 'circle-check',
    importance: 'ok',
    description: 'no-faults'
  } as const
}

function getUpdateStatus(state: { isUpdateNeeded: boolean }): DeviceStatus {
  const base = { label: 'Update' }
  if (state.isUpdateNeeded)
    return {
      ...base,
      faIcon: 'cloud-arrow-up',
      importance: 'important',
      description: 'update-available'
    } as const
  return { ...base, importance: 'hidden' } as const
}

function getTrafficStatus(state: { trafficState?: 'L' | 'W' | string }): DeviceStatus {
  const base = { label: 'data-volume' }
  if (state.trafficState === 'L')
    return {
      ...base,
      faIcon: 'gauge-max',
      importance: 'error',
      description:
        'data-overage'
    } as const
  if (state.trafficState === 'W')
    return {
      ...base,
      faIcon: 'gauge-high',
      importance: 'warn',
      description: 'data-near-max'
    } as const
  return { ...base, faIcon: 'gauge-min', importance: 'ok', description: 'Ok' } as const
}

function getLastMessageStatus(
  d: Extract<Device, { deviceType: 'luminaire' | 'cps' | 'gateway' }>
): DeviceStatus {
  const base = { label: 'last-update' }
  if (!d.lastMessage)
    return {
      ...base,
      faIcon: 'message-question',
      description: 'last-msg-unknown',
      importance: 'normal'
    } as const
  return {
    ...base,
    faIcon: 'message-check',
    description: 'last-msg',
    importance: 'normal'
  } as const
}

function getCpsWarningStatus(d: Cps): DeviceStatus {
  // CPS isn't yet integrated into the device-detail page. The following status will only be shown in DeviceStatusIcons.
  const base = { label: 'failure' }
  if (
    (d.config.enableOperation && !d.state.operation) ||
    (d.config.enableLuminaireFailure && d.state.luminaireFailure) ||
    (d.config.enableMalfunction && d.state.malfunction)
  )
    return {
      ...base,
      faIcon: 'triangle-exclamation',
      description: 'fault-occured',
      importance: 'error'
    }

  return { ...base, faIcon: 'check', description: 'no-failure', importance: 'normal' }
}

function getCpsBatteryStatus(d: Cps): DeviceStatus {
  // CPS isn't yet integrated into the device-detail page. The following status will only be shown in DeviceStatusIcons.
  const base = { label: 'batterie-mode' }
  if (d.config.enableBatteryActive && d.state.batteryActive)
    return { ...base, faIcon: 'battery-half', description: 'active', importance: 'normal' }

  return { ...base, faIcon: 'check', description: 'not-active', importance: 'normal' }
}

type DeviceStatusKey =
  | 'failure'
  | 'traffic'
  | 'batteryPower'
  | 'inspection'
  | 'update'
  | 'connection'
  | 'lastMessage'
  | 'cpsWarning'
  | 'cpsBattery'

/** Return all statuses as a keyed object to make them better readable in Aurelia */
export function getDeviceStatuses(d: Device) {
  const statuses: { [k in DeviceStatusKey]?: DeviceStatus } = {}

  if (d.deviceType === 'gateway' || d.deviceType === 'luminaire') {
    statuses.failure = getFailureStatus(d)
    statuses.batteryPower = getBatteryDeviceStatus(d.state)
  }
  if (d.deviceType === 'luminaire') {
    if (hasDefined(d.state, 'isUpdateNeeded')) statuses.update = getUpdateStatus(d.state)
    statuses.traffic = getTrafficStatus(d.state)
    statuses.inspection = getInspectionStatus(d)
  }
  if (d.deviceType === 'luminaire' || d.deviceType === 'cps' || d.deviceType === 'gateway') {
    statuses.connection = getConnectionStatus(d)
    statuses.lastMessage = getLastMessageStatus(d)
  }
  if (d.deviceType === 'cps') {
    statuses.cpsWarning = getCpsWarningStatus(d)
    statuses.cpsBattery = getCpsBatteryStatus(d)
  }

  return statuses
}
