import { inject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-templating'
import { SUPPORTED_COUNTRYS, SUPPORTED_SOCIALFORMS } from 'localization'
import { LocaleService } from 'services/locale-service'

export interface ICompanyInfo {
  name: string
  socialForm: string
  street: string
  number: string
  town: string
  zip: string
  country: string
  state: string
}
@inject(LocaleService)
export class CompanyInfoForm {
  /**
   * Available social forms
   */
  public socialFormOptions = SUPPORTED_SOCIALFORMS

  /**
   * Available countries
   */
  public countryOptions = SUPPORTED_COUNTRYS

  constructor(private readonly localServ: LocaleService){}

  
  public readonly getTranslatedCountries = (options:[])=> 
    options.map(coutry=> this.localServ.translate(`countries.${coutry}`))
    

  @bindable public value: ICompanyInfo
}
