import { Registration, UserApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'

import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import { DataStorage } from 'services/data-storage'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { ResultButton } from '../_controls/result-button'
import { findNavigationToRoute } from '../_utils/routing'
import { reportErr } from '../errorReporting'
import { LocaleService } from 'services/locale-service'

const LOG = getLogger('registration')

const TAG = 'REGISTRATIONDATA'

function isPasswordSafe(pw: string) {
  return pw.length > 7
}

const EMPTY_REGISTRATION: Registration = {
  user: {
    id: 0,
    login: '',
    firstName: '',
    lastName: '',
    title: '',
    sex: '',
    phone: '',
    mobile: '',
    email: '',
    skype: ''
  },
  password: ''
}

@autoinject
export class RegistrationPage {
  public formRoot: HTMLFormElement
  public submitButton: ResultButton

  public data = EMPTY_REGISTRATION
  public passwordConfirmation = ''

  constructor(
    private readonly router: Router,
    private readonly userApi: UserApi,
    private readonly locale: LocaleService,
    private readonly storage: DataStorage
  ) {}

  /**
   * On activation, attempt to reload previously entered details and display the requested step.
   * If no existing details can be found, then the user will start at step 0.
   *
   * @param params - the requested step to display.
   */
  public activate() {
    if (this.storage.hasItem(TAG)) {
      this.data = this.storage.getItem(TAG)
    } else {
      this.storage.setItem(TAG, this.data)
    }
  }

  /**
   * Navigate either to the previous step, or return home if the wizard is already in the first step.
   */
  public async navigateUp() {
    // we use page navigation to use the browser history with back and forward button handling
    this.storage.removeItem(TAG)
    findNavigationToRoute(this.router, 'home').catch(async () => undefined)
  }

  public async onSubmitClicked() {
    const progress = this.submit()
    this.submitButton.displayProgress(progress)
  }

  public async submit(): Promise<ActionResult> {
    if (!this.formRoot.checkValidity()) {
      this.formRoot.reportValidity()
      return { hasError: true }
    }

    if (this.passwordConfirmation !== this.data.password)
      throw new Error(this.locale.translate('signup.account.passwordConfirmationMessage'))

    const isLoginAvailable = this.userApi.isLoginAvailable({ username: this.data.user.login! })
    if (!isLoginAvailable) throw new Error(this.locale.translate('signup.failure-notification.username-exists'))

    await this.userApi.registerUser({
      acceptDSV: true,
      acceptAGB: true,
      body: {
        password: this.data.password,
        user: { ...this.data.user, email: this.data.user.login! }
      }
    })

    this.storage.removeItem(TAG)
    findNavigationToRoute(this.router, 'success-notification', {
      userEmail: this.data.user.email
    }).catch((e) => reportErr(e))
  }
}
