import { User } from '@nubix/spica-cloud-backend-client'
import { computedFrom } from 'aurelia-binding'
import { inject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-templating'
import { SUPPORTED_ADDRESSFORMS, SUPPORTED_TITLES } from 'localization'
import { LocaleService } from 'services/locale-service'
@inject(LocaleService)
export class ContactInfoForm {
  /**
   * Available options for titles
   */
  public titleOptions = SUPPORTED_TITLES

  /**
   * Available options for form of address
   */
  public formOfAddressOptions = SUPPORTED_ADDRESSFORMS

  @bindable public value: User


  constructor(private readonly localeServ:LocaleService){

  }
  
  public get translatedTitles(){
    
    return this.titleOptions.map((title, i)=>(
      this.localeServ.translate(i !== 0 ? `person.${title}` : '')
    ))
    
  }

  
  public get translatedAdressForms(){
    
    return this.formOfAddressOptions.map(addForm=>(
      this.localeServ.translate( `person.${addForm}`)
    ))
    
  }
}
