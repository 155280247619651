import { FacilityGroup } from '@nubix/spica-cloud-backend-client'
import { bindable, computedFrom, inject } from 'aurelia-framework'
import { ContactRole } from 'spica-cloud-shared/lib/model/permissions'
import { ROLE_DESCRIPTIONS } from '../../../model/permissions'
import * as styles from './facility-group-description.module.scss'
import { LocaleService } from 'services/locale-service'


@inject(LocaleService)
export class FacilityGroupDescription {
  styles = styles
  @bindable facilityGroup: FacilityGroup | undefined

  /** If unset, the role is determined from the company object */
  @bindable myRole: ContactRole | undefined

  private readonly localServ:LocaleService;

  constructor(localServ: LocaleService){
    this.localServ = localServ
  }

  public getTranslatedRole(role: ContactRole): string {
    return this.localServ.translate(`general.roles.${role}`)
  }
  @computedFrom('facilityGroup')
  get roleDescription() {
    if (this.myRole) return  'hola'//ROLE_DESCRIPTIONS[this.myRole]
    if (!this.facilityGroup?.myRole) return ''
    
    return this.getTranslatedRole(this.facilityGroup.myRole)
    //return 'hiloo' // ROLE_DESCRIPTIONS[this.facilityGroup.myRole]
  }
}
