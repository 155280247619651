import { Inspection, Luminaire } from '@nubix/spica-cloud-backend-client'
import { computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-dependency-injection'
import { I18N } from 'aurelia-i18n'
import { bindable } from 'aurelia-templating'
import moment from 'moment'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { hideAll } from 'tippy.js'
import { assertUnreachable } from '../../../../_utils/utils'

@autoinject()
export class InspectionView {
  //region Props
  @bindable() inspection?: Inspection
  @bindable() luminaire?: Luminaire
  @bindable() startTest: () => Promise<void>
  @bindable() canRunTests: boolean
  //endregion

  //region Derived State
  @computedFrom('inspection')
  public get inspectionState() {
    return this.inspection?.result
  }

  @computedFrom('luminaire')
  get may() {
    return getPermissionTable(this.luminaire?.myRole)
  }

  @computedFrom('inspectionState')
  public get inspectionStateDescription() {
  
    switch (this.inspectionState) {
      case 'EMERGENCY':
        return 'EMERGENCY'
      case 'ERROR':
        return 'ERROR'
      case 'STOPPED':
        return  'STOPPED'
      case 'NO_CAPACITY':
        return 'NO_CAPACITY'
      case 'SUCCESS':
        return 'SUCCESS'
      case 'BATTFAIL':
        return 'BATTFAIL'
      case 'LEDFAIL':
        return 'LEDFAIL'
      case 'TOTALFAIL':
        return 'TOTALFAIL'
      case 'FAILED':
        return 'FAILED'
      case 'RUNNING':
        return 'FAILED'
      case 'TIMEOUT':
        return 'TIMEOUT'
      case 'DUE':
        return 'DUE'
      case undefined:
        return '-'
      default:
        return assertUnreachable(this.inspectionState)
    }
  }
  public get translatedStateDescription(){
    return this.i18n.tr(`devices.tests.${this.inspectionStateDescription}`)
  }

  @computedFrom('inspection')
  public get inspectionDate() {
    if (!this.inspection?.date) return undefined
    moment.locale(this.i18n.getLocale())
    return moment(this.inspection?.date).fromNow()
  }

  /** A class that determines the color of the inspection */
  @computedFrom('inspectionState')
  public get inspectionStateColor() {
    switch (this.inspectionState) {
      case 'EMERGENCY':
      case 'ERROR':
      case 'STOPPED':
      case 'TIMEOUT':
      case 'NO_CAPACITY':
        return 'aborted'
      case 'SUCCESS':
        return 'success'
      case 'BATTFAIL':
      case 'LEDFAIL':
      case 'TOTALFAIL':
      case 'FAILED':
        return 'failed'
      case 'RUNNING':
      case 'DUE':
        return 'active'
      case undefined:
        return 'nonexistent'
      default:
        return assertUnreachable(this.inspectionState)
    }
  }

  @computedFrom('inspectionState')
  public get inspectionStateIcon() {
    switch (this.inspectionState) {
      case 'SUCCESS':
        return 'fa-check'
      case 'BATTFAIL':
      case 'LEDFAIL':
      case 'TOTALFAIL':
      case 'FAILED':
        return 'fa-xmark'
      case 'EMERGENCY':
      case 'ERROR':
      case 'STOPPED':
      case 'TIMEOUT':
      case 'NO_CAPACITY':
        return 'fa-question'
      case 'RUNNING':
        return 'fa-spinner-third fa-spin'
      case 'DUE':
      case undefined:
        return 'fa-clock'
      default:
        return assertUnreachable(this.inspectionState)
    }
  }

  //endregion

  //region Lifecycle
  public constructor(private readonly i18n: I18N) {}

  //endregion

  public onCancel() {
    hideAll()
  }
}
