import { UserApi } from '@nubix/spica-cloud-backend-client'
import { passwortRequirements } from '_utils/utils'

import { HtmlValidationRenderer } from '_utils/validation/htmlValidationRenderer'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import {
  validateTrigger,
  ValidationController,
  ValidationControllerFactory,
  ValidationRules
} from 'aurelia-validation'
import { AuthService } from 'services/auth-service'
import { findNavigationToRoute } from '../../_utils/routing'
import { reportErr } from 'errorReporting'
import { LocaleService } from 'services/locale-service'

const LOG = getLogger('change-login')

/**
 * Page to edit login data. The user can change the login name and used password here. If the user changes the login
 * password only then the password change is stored at the Backend and is immediately in effect. If the user changes the
 * login name than the user is logged out since the account is deactivated immediately. So before changing the login
 * name the page shows a warning.
 */
@autoinject
export class ChangeLogin {
  public userEmail?: string
  public oldPassword: string
  public userPassword?: string
  public passwordConfirmation?: string

  public validationController: ValidationController

  public formRoot: HTMLFormElement
  public passwordConfirmationField: HTMLInputElement
  public passwordConfirmationValidity: string

  private userEmailPrev: string

  constructor(
    private readonly router: Router,
    private readonly userApi: UserApi,
    private readonly authService: AuthService,
    readonly validationFactory: ValidationControllerFactory,
    private readonly localServ: LocaleService
  ) {
    this.validationController = validationFactory.createForCurrentScope()
    this.validationController.addRenderer(new HtmlValidationRenderer())
  }

  /**
   * Load login-details about an user from the server and display it.
   * @param params - Parameters to be passed via the url of the page. See {@link ILoginParams}
   */
  public async activate() {
    const user = await this.userApi.getCurrentUser()
    this.userEmail = user.login ? user.login : ''
    this.userEmailPrev = user.login ? user.login : ''
  }

  /**
   * Navigate to the account overview
   */
  public navigateUp() {
    findNavigationToRoute(this.router, 'all-options').catch((e) => reportErr(e))
  }

  /**
   * Validate and submit updated login information.
   */
  public async nextStep() {
    this.validationController.reset()
    this.validationController.changeTrigger(validateTrigger.blur)
    ValidationRules.ensure('userEmail')
      .displayName('Email')
      .required()
      .email()
      .withMessage(this.localServ.translate('signup.account.emailValidationMessage'))
      .then()
      .satisfies(
        (value, _object) =>
          this.userEmailPrev === value || this.userApi.isLoginAvailable({ username: value })
      )
      .withMessage(this.localServ.translate('signup.account.failure-notification.username-exists'))

      .ensure('oldPassword')
      .displayName(this.localServ.translate('signup.oldpass-req'))
      .required()
      .withMessage(this.localServ.translate('signup.failure-notification.oldpass-missing'))

      .ensure('userPassword')
      .displayName(this.localServ.translate('signup.newpass-req'))
      .required()
      .withMessage(this.localServ.translate('signup.account.field-req'))

      .matches(passwortRequirements)
      .withMessage(this.localServ.translate('signup.account.passwordValidationMessage'))

      .ensure('passwordConfirmation')
      .displayName(this.localServ.translate('signup.pass-confirm-req'))
      .satisfies((value: any, object: any) => value === (object as ChangeLogin).userPassword)
      .withMessage(this.localServ.translate('signup.account.passwordConfirmationMessage'))

      .on(this)

    if (!(await this.validationController.validate()).valid) {
      this.formRoot.reportValidity()
      throw new Error()
    }

    const isChangingPassword =
      this.userPassword && this.userPassword.length > 0 && this.userPassword !== this.oldPassword
    const isChangingMail = this.userEmail !== this.userEmailPrev

    if (!isChangingMail && !isChangingPassword) return

    let result: Response

    try {
      result = (await this.userApi.resetLogin({
        body: {
          confirm: this.oldPassword,
          username: isChangingMail ? this.userEmail : undefined,
          password: this.userPassword
        }
      })) as Response
    } catch (e: any) {
      // TODO: e: any is Fixed with multi-user-update
      if (e.statusCode === 403 || e.status === 403) {
        throw new Error(this.localServ.translate('signup.failure-notification.verify-pass'))
      }

      throw new Error(this.localServ.translate('signup.failure-notification.change-failed'))
    }

    if (result.status && result.status !== 204 && result.status !== 200) {
      LOG.debug('error during changing login', result.status, result.statusText)
      throw new Error(this.localServ.translate('signup.failure-notification.change-failed'))
    }

    if (isChangingMail) {
      // silent logout
      this.authService.logout()
      // show email hint to user
      await findNavigationToRoute(this.router, 'confirm-email-home', {
        userEmail: this.userEmailPrev
      })

      return
    }

    await this.activate() // TODO: Maybe don't call lifecycle functions ourselves
  }
}
