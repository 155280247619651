import { autoinject } from 'aurelia-dependency-injection'
import { DialogService } from 'aurelia-dialog'
import { RoutableComponentActivate, Router } from 'aurelia-router'
import { bindable } from 'aurelia-templating'

import { AuthService } from 'services/auth-service'
import { ActionResult, BaseButton } from './_controls/presentation/widget/base-button'
import { findNavigationToRoute } from './_utils/routing'
import { LocaleService } from 'services/locale-service'

/**
 * User login component. Shows a field for username and password and a link to reset the password if the user can not
 * remember the login.
 */
@autoinject
export class PasswordRequest implements RoutableComponentActivate {
  /**
   * entered user password.
   */
  public userPassword: string
  /**
   * entered user name.
   */
  public userName: string

  /**
   * URL where to navigate after successfull login.
   */
  @bindable()
  public target: string

  /**
   * Login button.
   */
  private readonly loginButton: BaseButton

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly localeServ: LocaleService
  ) {
    this.dialogService = dialogService
  }

  public activate(params: any) {
    // get username  from parameter
    this.userName = params.userName
  }

  /**
   * Open user registration pages. (Registration button clicked)
   */
  public async registration() {
    return findNavigationToRoute(this.router, 'signup')
  }

  private navigateToNextRoute() {
    if (this.target && this.target.indexOf('logout') < 0) {
      this.router.navigate(this.target, { replace: true })
    } else {
      findNavigationToRoute(this.router, 'app')
    }
  }

  /**
   * Login button clicked. Then try to login with given user credentials.
   */
  private onLoginClicked() {
    this.loginButton.displayProgress(this.login())
  }

  private async login(): Promise<ActionResult> {
    await this.authService.login(this.userName, this.userPassword)
    this.navigateToNextRoute()
  }
}
