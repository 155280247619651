import { EventAggregator } from 'aurelia-event-aggregator'
import { autoinject } from 'aurelia-framework'
import { I18N } from 'aurelia-i18n'
import { getLogger } from 'aurelia-logging'
import { SUPPORTED_LANGUAGES } from 'localization'

/**
 * This service handles multiple translations of pages in the web app. The user can switch the translation in their
 * browser without interfering with other users translation.
 */

@autoinject
export class LocaleService {
  private static readonly LOG = getLogger('locale')
  public constructor(private readonly i18n: I18N) {
    
  }
  public currentLanguange = this.i18n.getLocale()



  public cleanLanguage = (lang: string | null): string => {
    return lang?.substring(0, 2)?? ''
  }




  public async loadLanguage() {
    // Clean and get the language from either saved preference or browser default
    const browserLang = this.cleanLanguage(navigator.language ?? 'en')
    const browserSupportedLang = (SUPPORTED_LANGUAGES.includes(browserLang)) ? browserLang : 'en' 
    const savedLang = this.cleanLanguage(window.localStorage.getItem('savedLanguage'))
  
    const langToSet = savedLang || browserSupportedLang
    
    try {
      await this.setLanguage(langToSet)
    } catch (error) {
      const i18nLog = getLogger('aurelia-i18n')
      i18nLog.error('Failed to set locale', error)
    }
  }
  
  public saveLanguage(language:string){
    if (!language) return undefined
    // save language to localStorage
    const cleanedLang = this.cleanLanguage(language)
    window.localStorage.setItem('savedLanguage',cleanedLang)
    
  }

  public async setLanguage(language: string) {
    if (!language) return undefined

    LocaleService.LOG.info('refresh language', language)
    await this.i18n.setLocale(language)
    this.currentLanguange = this.i18n.getLocale()
    //this.EventAggregator.publish('languageChanged', language)
  }

  public  getLanguage() {
    
    return this.i18n.getLocale()
  }

  /**
   * Get translation for current language.
   * @param key - key to translation record
   * @param options - data that is stored in the translation
   */
  public translate(key: string, options?: any): string {
    return this.i18n.tr(key, options)
  }
}
