import { mutateAllCaches } from '@nubix/npm-utils/src/cache/GlobalControl'
import {
  ContactInvitationApi,
  ContactInvitationWithCompany
} from '@nubix/spica-cloud-backend-client'
import { autoinject, bindable, computedFrom } from 'aurelia-framework'
import { ROLE_DESCRIPTIONS } from '../../model/permissions'
import { ContactInvitationService } from '../../services/contact-invitation-service'

import styles from './incoming-invitation-cell.module.scss'
import { LocaleService } from 'services/locale-service'

@autoinject
export class IncomingInvitationCell {
  styles = styles

  @bindable invitation?: ContactInvitationWithCompany

  @computedFrom('invitation')
  get roleDescription() {
    if (!this.invitation) throw new Error()
    return this.localServ.translate(`general.roles.${this.invitation.role}`)// ROLE_DESCRIPTIONS[this.invitation.role]
  }

  constructor(
    private readonly contactInvitationApi: ContactInvitationApi,
    private readonly localServ: LocaleService,
    private readonly contactInvitationService: ContactInvitationService
  ) {}

  async resolve(resolution: 'accept' | 'reject') {
    if (!this.invitation) throw new Error()
    mutateAllCaches({ requestProgress: Promise.resolve() })
    const requestProgress = this.contactInvitationApi.resolveContactInvitation({
      id: this.invitation.id,
      body: { resolution }
    })
    mutateAllCaches({ requestProgress })
    await requestProgress
  }
}
