import { IExtRouteConfig } from '_utils/routing'
import { autoinject } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { Router, RouterConfiguration } from 'aurelia-router'

/**
 * This class specifies all routes related to facilities.
 */
@autoinject
export class Facilities {
  public router: Router

  public configureRouter(config: RouterConfiguration, router: Router) {
    const routes: IExtRouteConfig[] = [
      {
        route: ['', 'facility-list'],
        name: 'facility-list',
        moduleId: PLATFORM.moduleName('./facility-list'),
        title: 'Facilities overview',
        settings: {
          b: false,
          requiresAuthentication: true
        }
      },
      {
        route: [':facilityId', 'facility-edit'],
        name: 'facility-edit',
        moduleId: PLATFORM.moduleName('./facility-edit'),
        title: 'Edit facility',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'facility-add',
        name: 'facility-add',
        moduleId: PLATFORM.moduleName('./facility-add'),
        title: 'Add Facility',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: ['home'],
        name: 'home',
        moduleId: PLATFORM.moduleName('home'),
        nav: true,
        title: 'Fischer Service Cloud',
        settings: {
          b: false,
          d: true
        }
      }
    ]

    config.map(routes)

    this.router = router
  }
}
